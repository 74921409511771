<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <a-select
          v-model="defaultOptionValue"
          style="width: 120px"
          @change="handleSelectChange"
        >
          <a-select-option v-for="option in selectOptions" :key="option.value">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <ve-pie
      :data="chartData"
      :settings="settings"
      height="500px"
      :legend="legend"
    ></ve-pie>
  </div>
</template>

<script>
import { PointRecordModel } from "../../models/pointRecord";
const pointRecordModel = new PointRecordModel();
export default {
  name: "PointAnalysis",
  data() {
    return {
      recordCount: 0,
      defaultOptionValue: 10,
      chartData: {
        columns: ["关键字", "积分数量"],
        rows: [],
      },
      settings: {
        label: "",
        radius: 150,
        offsetY: 300,
        // limitShowNum: 30,
      },
      selectOptions: [
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 40 },
        { value: 50 },
      ],
      legend: {
        // type: "scroll",
        orient: "vertical",
        right: "3%",
        top: "5%",
        bottom: 50,
        data: [],
      },
    };
  },

  mounted() {
    this.fetchChartData();
  },

  methods: {
    // onChangeRecordCount() {
    //   this.fetchChartData();
    // },
    handleSelectChange(value) {
      this.defaultOptionValue = value;
      this.fetchChartData();
    },
    fetchChartData() {
      this.loading = true;
      pointRecordModel
        .getPointChartData(this.defaultOptionValue)
        .then((res) => {
          if (res.code == 0) {
            this.loading = false;
            this.chartData.rows = res.data;
            this.legend.data = res.columns;
          }
        });
    },
  },
};
</script>

<style scoped>
</style>