import {HTTP} from '../utils/axios'

class PointRecordModel extends HTTP {
    /*首页渲染*/
    index(category = '', key = '', getWay = '', page = 1) {
        return this.request({
            url: 'board/point_records',
            method: "GET",
            data: {
                category: category,
                key: key,
                get_way: getWay,
                page: page
            }
        })
    }

    totalAmount() {
        return this.request({
            url: 'board/point_records/total_amount',
            method: "GET"
        })
    }

    amountThread(date1 = '', date2 = '') {
        return this.request({
            url: 'board/point_records/amount_thread',
            method: "GET",
            data: {
                date1: date1,
                date2: date2
            }
        })
    }

    // 获取积分图表数据
    getPointChartData(limitCount) {
        return this.request({
            url: 'board/point_records/analysis_chart_data',
            method: "GET",
            data: {
                limit_count: limitCount
            }
        })
    }
}

export {PointRecordModel}