<template>
  <div style="margin-top: 20px; float: right">
    <a-pagination
      :current="current"
      :defaultPageSize="pagination.defaultPageSize"
      show-quick-jumper
      :total="total"
      :show-total="(total) => `共 ${total} 数据`"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pagination: {
        defaultPageSize: 20,
      },
    };
  },
  methods: {
    handleChange(page) {
      this.$emit("handleChange", page);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>